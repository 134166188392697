import React, { ReactNode } from "react"
import { NextSeo, NextSeoProps } from "next-seo"
import Image from "next/image"
import { Text } from "@components/ui/typograhpy/Text"
import Link from "next/link"
import { Toasts } from "@components/Toasts"
import { Logo } from "@components/Logo"
import { cn } from "@lib/cn"

type SplitScreenPageProps = Omit<NextSeoProps, "children"> & {
  footerText?: any
  variant?: "default" | "error"
  children: ReactNode
}

export const SplitScreenPage = ({
  children,
  footerText,
  variant = "default",
  ...rest
}: SplitScreenPageProps) => {
  return (
    <>
      <NextSeo {...rest} />
      <Link href="/" passHref className={cn(
        "absolute mt-4 ml-6 overflow-hidden", 
        "focus-visible:outline-0 focus-visible:rounded-[3px] focus-visible:shadow-focus-visible"
      )}>
        <Logo variant="full" />
      </Link>
      <div className={cn(
        "grid p-6 h-screen grid-cols-[1fr]", 
        "md:grid-cols-[2fr_1fr] md:gap-x-4 lg:grid-cols-[1fr_1fr]",
        variant === "error" && "grid-rows-[22rem_1fr] md:grid-rows-[unset]"
      )}>
        <div className="flex w-full h-auto justify-self-center items-center max-w-[364px]">
          {children}
          <Text variant="micro" className="absolute mb-6 max-w-[364px] bottom-0 text-center md:text-left text-shade-500">
            {footerText}
          </Text>
        </div>
        <div className={cn(
          "hidden md:block relative",
          variant === "error" && "block"
        )} data-variant={variant}>
          <Image
            src={getImageSrc(variant)}
            alt="example picture with before and after"
            layout="fill"
            objectFit="cover"
            className="rounded-md"
            priority
          />
        </div>
      </div>
      <Toasts />
    </>
  )
}

function getImageSrc(variant: SplitScreenPageProps["variant"]) {
  if (variant === "default") {
    return "/assets/auth-page-picture.jpg"
  }
  if (variant === "error") {
    return "/assets/error-splitpage.jpg"
  }
  return ""
}
