import { LoginForm } from "@components/_auth/login-form"
import { SplitScreenPage } from "@components/_auth/SplitScreenPage"
import { useConfirmationAndResetNotification } from "@lib/use-confirmation-and-reset-notification"
import { useQueryClient } from "@tanstack/react-query"
import { GetServerSideProps, NextPage } from "next"
import { BuiltInProviderType } from "next-auth/providers/index"
import { ClientSafeProvider, getProviders, LiteralUnion } from "next-auth/react"
import { useEffect } from "react"

type LoginPageProps = {
  providers: Record<
    LiteralUnion<BuiltInProviderType, string>,
    ClientSafeProvider
  > | null
}

const LoginPage: NextPage<LoginPageProps> = ({ providers }) => {
  const client = useQueryClient()
  useConfirmationAndResetNotification()

  useEffect(() => {
    // Always reset cache before login
    client.clear()
  }, [client])

  return (
    <SplitScreenPage
      title="Login"
      description="Login to your Viesus Cloud account"
    >
      <LoginForm providers={providers} />
    </SplitScreenPage>
  )
}

export const getServerSideProps: GetServerSideProps = async () => {
  const providers = await getProviders()

  return {
    props: {
      providers,
    },
  }
}

export default LoginPage
