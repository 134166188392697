import { Button } from "@components/ui/button"
import { Heading } from "@components/ui/typograhpy/Heading"
import { Text } from "@components/ui/typograhpy/Text"
import { BuiltInProviderType } from "next-auth/providers/index"
import { ClientSafeProvider, LiteralUnion, signIn } from "next-auth/react"
import Link from "next/link"
import { FC } from "react"
import { LoginFormForm as Form } from "./Form"
import { cn } from "@lib/cn"

type LoginFormProps = {
  providers: Record<
    LiteralUnion<BuiltInProviderType, string>,
    ClientSafeProvider
  > | null
}

export const LoginForm: FC<LoginFormProps> = ({ providers }) => {
  const google = providers && providers.google
  const apple = providers && providers.apple
  return (
    <div className="flex flex-wrap justify-center md:justify-start w-full">
      <Heading variant="h2" className="mb-8">
        Log in
      </Heading>
      <div className="flex flex-col items-center w-full">
        <Form />
        {(google || apple) && (
          <>
            {google && (
              <Button
                variant="secondary"
                data-testid="login-with-google"
                leftIcon="google"
                onClick={() => signIn(google.id as any)}
                className="mb-4"
                width="fill"
              >
                Continue with Google
              </Button>
            )}
            {apple && (
              <Button
                variant="secondary"
                leftIcon="apple"
                className="mb-4"
                onClick={() => signIn(apple.id as any)}
                width="fill"
              >
                Continue with Apple
              </Button>
            )}
          </>
        )}
        <Text variant="small" className="bg-default bg-clip-text text-transparent">
          {"New to Viesus? "}
          {
            <Link
              href="/signup/create-account"
              passHref
              className={cn(
                "bg-hover bg-no-repeat bg-left-bottom bg-[length:0%_1px]",
                "transition-[background-size] duration-150 ease-in-out",
                "media-screen:hover:bg-[length:100%_1px]"
              )}
            >
              Create an account
            </Link>
          }
          !
        </Text>
      </div>
    </div>
  )
}
