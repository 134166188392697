import { cn } from "@lib/cn"
import React, { FC } from "react"
import IconDanger from "./ui/icons/IconDanger"
import { Text } from "./ui/typograhpy/Text"

type ErrorNotificationProps = {
  message: string
  className?: string
}

export const ErrorNotification: FC<ErrorNotificationProps> = ({
  message,
  className,
}) => {
  return (
    <div
      className={cn(
        "mb-5 flex w-full items-center rounded-md bg-danger px-4 py-3",
        className,
      )}
    >
      <IconDanger size={24} />
      <Text variant="regular" className="ml-2">
        {message}
      </Text>
    </div>
  )
}
